
// import {ILocalNotification, LocalNotifications} from "@awesome-cordova-plugins/local-notifications";
import {
  IonApp,
  IonCard, IonCardContent, IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  getPlatforms, IonCardHeader,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { checkmarkOutline, checkmarkSharp, bedOutline, bedSharp, volumeHighOutline, volumeHighSharp, contractSharp, contractOutline, expandOutline, expandSharp, informationCircleSharp, informationCircleOutline, cubeOutline, cubeSharp, listOutline, listSharp, pieChartOutline, pieChartSharp, timerOutline, timerSharp, settingsOutline, settingsSharp, timeOutline, timeSharp, homeOutline, homeSharp, stopwatchOutline, stopwatchSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import {Notificator} from "@/Helpers";
import {StatusBar} from "@capacitor/status-bar";
import {KeepAwake} from "@capacitor-community/keep-awake";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      notificationPermission: "",
      statusBarVisible: true,
      stayAwake: false,
    }
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Home',
        url: '/home',
        iosIcon: homeOutline,
        mdIcon: homeSharp
      },
      {
        title: 'Time',
        url: '/timers/time',
        iosIcon: timeOutline,
        mdIcon: timeSharp
      },
      {
        title: 'Stopwatch',
        url: '/timers/stopwatch',
        iosIcon: stopwatchOutline,
        mdIcon: stopwatchSharp
      },
      {
        title: 'Countdown',
        url: '/timers/countdown',
        iosIcon: timerOutline,
        mdIcon: timerSharp
      },
      {
        title: 'Pomodoro',
        url: '/timers/pomodoro',
        iosIcon: pieChartOutline,
        mdIcon: pieChartSharp
      },
      {
        title: 'Interval Timer',
        url: '/timers/interval',
        iosIcon: listOutline,
        mdIcon: listSharp
      },
      {
        title: 'Timebox',
        url: '/timers/timebox',
        iosIcon: cubeOutline,
        mdIcon: cubeSharp
      },
      {
        title: 'Imprint',
        url: '/imprint',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp,
      }
      // {
      //   title: 'Inbox',
      //   url: '/folder/Inbox',
      //   iosIcon: mailOutline,
      //   mdIcon: mailSharp
      // },
      // {
      //   title: 'Outbox',
      //   url: '/folder/Outbox',
      //   iosIcon: paperPlaneOutline,
      //   mdIcon: paperPlaneSharp
      // },
      // {
      //   title: 'Favorites',
      //   url: '/folder/Favorites',
      //   iosIcon: heartOutline,
      //   mdIcon: heartSharp
      // },
      // {
      //   title: 'Archived',
      //   url: '/folder/Archived',
      //   iosIcon: archiveOutline,
      //   mdIcon: archiveSharp
      // },
      // {
      //   title: 'Trash',
      //   url: '/folder/Trash',
      //   iosIcon: trashOutline,
      //   mdIcon: trashSharp
      // },
      // {
      //   title: 'Spam',
      //   url: '/folder/Spam',
      //   iosIcon: warningOutline,
      //   mdIcon: warningSharp
      // }
    ];
    // const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    const path = window.location.pathname.split('timers/')[1];
    
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      // labels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      settingsOutline,
      settingsSharp,
      timerOutline,
      timerSharp,
      expandOutline,
      expandSharp,
      contractOutline,
      contractSharp,
      volumeHighOutline,
      volumeHighSharp,
      bedOutline,
      bedSharp,
      checkmarkOutline,
      checkmarkSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  },
  mounted() {
    this.getNotificationPermission();

    // If we are on mobile, get the status bar visibility
    if(this.platforms().includes('hybrid')) {
      this.getStatusBarVisible();
    }
  },
  // computed: {
  // },
  methods: {
    async awakeSupported(): Promise<boolean> {
      const supported = await KeepAwake.isSupported();
      return supported.isSupported;
    },
    /**
     * Toggle if the device shoud stay awake.
     */
    async toggleAwake(): Promise<void> {
      if (this.stayAwake) {
        await KeepAwake.allowSleep();
        this.stayAwake = false;
        return;
      }
      await KeepAwake.keepAwake();
      this.stayAwake = true;
    },
    /**
     * Check if the status bar is visible. Sets data and returns value.
     */
    async getStatusBarVisible(): Promise<boolean> {
      const statusBarInfo = await StatusBar.getInfo();
      this.statusBarVisible = statusBarInfo.visible;
      return statusBarInfo.visible;
    },
    /**
     * Toggle the status bar of the OS.
     */
    async toggleStatusBar(): Promise<void> {
      if (await this.getStatusBarVisible()) {
        await StatusBar.hide();
        this.statusBarVisible = false;
      } else {
        await StatusBar.show();
        this.statusBarVisible = true;
      }
      // Get ste current status to show the correct button.
      // await this.getStatusBarVisible();
    },
    platforms(): string {
      return getPlatforms().join(', ');
    },
    testSound(): void {
      const audio = new Audio(`${this.publicPath}assets/sounds/193022__truthiswithin__tibetan-singing-bowl-struck.mp3`);
      audio.play();

    },
    async getNotificationPermission() {
      this.notificationPermission = await Notificator.notificationPermission();
    },
    notifyMe(): void {
      Notificator.notifyMe("Hi, there!", "I'm testing notifications.");
      // Repeat the check to see if it changed since the click, which might ask for permission.
      this.getNotificationPermission();
    },
  }
});
