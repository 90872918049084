import {LocalNotifications} from "@capacitor/local-notifications";
import {getPlatforms} from "@ionic/vue";

export const Notificator = {
    /**
     * Checks if notifications are working.
     * Returns "default" | "denied" | "granted" from Web Notifications
     *         'prompt' | 'prompt-with-rationale' | 'granted' | 'denied' from LocalNotifications
     *         "nosupport" if both are not supported.
     */
    async notificationPermission(): Promise<string> {
        if(getPlatforms().includes('hybrid')) {
            const permission = await LocalNotifications.checkPermissions();
            return permission.display;
            // return enabled ? "granted" : "disabled";
        }
        if (typeof Notification === 'undefined') {
            return "nosupport";
        }
        return Notification.permission as string;
        // return "granted";
    },

    /**
     * Send a notification, if it's on mobile, it uses LocalNotification, otherwise regular web notification
     * @param title
     * @param body
     */
    async notifyMe(title: string, body?: string) {
        if(getPlatforms().includes('hybrid')) {
            await this.notifyMeMobile(title, body);
        } else if(getPlatforms().includes('desktop')) {
            await this.notifyMeWeb(title, body);
        }
    },

    /**
     * Notify via web worker, not used atm.
     */
    async notifyMeWebWorker() {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration && 'showNotification' in registration) {
            await registration.showNotification("Test title", {body: "Test body"});
        }
    },

    /**
     * Notify via LocalNotification on mobile devices. Asks for permission if not done yet.
     * @param title
     * @param body
     */
    async notifyMeMobile(title: string, body = "") {
        // The notification to send.
        const notification = {
                    id: 1,
                    title: title,
                    body: body,
                };

        // Send notification or ask for permission first.
        if (await this.notificationPermission() === "granted") {
            await LocalNotifications.schedule({
                notifications: [notification]
            })
        } else {
            LocalNotifications.requestPermissions().then(async (permission) =>{
                if (permission.display === "granted") {
                    await LocalNotifications.schedule({
                        notifications: [notification]
                    })
                }
            })
        }
    },

    /**
     * Send a notification or request permission on web browsers.
     * From https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission
     */
    async notifyMeWeb(title: string, body?: string) {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (await this.notificationPermission() === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            new Notification(title, {body: body});
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    new Notification(title, {body: body});
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    },
}