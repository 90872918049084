import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import ImprintPage from '../views/ImprintPage.vue';
// import NewItem from '@/views/NewItem.vue';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  // {
    //   path: '/new',
    //   name: 'NewItem',
    //   component: NewItem,
    // },
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: ImprintPage
  },
  {
    path: '/timers/time',
    component: () => import ('@/views/Time.vue'),
  },
  {
    path: '/timers/stopwatch',
    component: () => import ('@/views/StopWatch.vue'),
  },
  {
    path: '/timers/countdown',
    component: () => import ('@/views/CountDown.vue'),
  },
  {
    path: '/timers/pomodoro',
    component: () => import ('@/views/PomodoroTimer.vue'),
  },
  // {
  //   path: '/folder/:id',
  //   component: () => import ('../views/FolderPage.vue'),
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
